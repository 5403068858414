import { Grid } from '@mui/material'
import React from 'react'
import MealItem from './MealItem'
import { useAppSelector } from '../../../redux/store'
import Loader from '../../../components/loader/loader'
import { Meal } from '../../../redux/Slices/MealsSlice'
import { motion ,useScroll} from "framer-motion";
import { varints } from "../../../utils/routesVariant";

const MealsList = () => {
    const { isLoading, isError, meals } = useAppSelector(state => state.meals)
    const filteredAndSortedMeals = meals
    .filter(meal => meal.visible === 1)
    .sort((a, b) => a.position - b.position);
    // if (isLoading) {
    //     return <Loader/>
    // }
 
    return (
        <>
        {isLoading ? <Loader /> : 
        <Grid spacing={2} container >

{filteredAndSortedMeals.map((meal, index) => (
      <Grid key={index} item xs={12} md={6} lg={4}>
          <motion.div
        variants={varints.childrenvarinats}
        initial={"initial"}
        animate={"final"}
        className="absolute bottom-2 w-full flex justify-center "
      >
        <MealItem meal={meal} />
        </motion.div>
      </Grid>
    ))} 

        </Grid>
        }</>
    )
}

export default MealsList