import React from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import drinks from "../../../assets/drinks.png";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { fetchMeals } from "../../../redux/Slices/MealsSlice";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

type CategoryProps = {
  image: string;
  name: string;
  id: number;
};

export const CategoryItem = ({ image, name, id }: CategoryProps) => {
  const dispatch = useAppDispatch();

  const chooseCategoryHandler = () => {
    dispatch(fetchMeals({ id }));
    localStorage.setItem("selectedCatigory", id.toString());

  };

  const { language } = useAppSelector((state) => state.language);

  return (
    <Link onClick={chooseCategoryHandler} to={`/meals`}>
      {/* <Stack
        mb={2}
        alignItems={"center"}
        direction={language === "ar" ? "row-reverse" : "row"}
        justifyContent={"space-between"}
        sx={{ background: "#fff", padding: "0.5rem 1rem", borderRadius: "5px" }}
      >
        <Stack
          alignItems={"center"}
          gap={2}
          direction={language === "ar" ? "row-reverse" : "row"}
        >
          <Box
            sx={{
              padding: "0.4rem",
              borderRadius: "5px",
              color: "#b56c00",
              width: "80px",
            }}
          >
            <img
              style={{ width: "80px",height:'80px', borderRadius: "5px" }}
              loading="lazy"
              src={image}
            />
          </Box>
          <Typography
            style={{
              textTransform: "capitalize",
              fontSize: "1.2rem",
              color: "#333",
              fontWeight: "600",
            }}
          >
            {name}
          </Typography>
        </Stack>
        {language === "ar" ? (
          <ChevronLeftIcon className="right" />
        ) : (
          <ChevronRightRoundedIcon className="right" />
        )}
      </Stack> */}

     <Box
                sx={{
                  textDecoration: "none",
                }}
                component={Link}
                to={`/meals`}
              >
                
                <Box
                sx={{
                   display: "flex",
                    justifyContent: "center",
                     background:'white', 
                    borderRadius: "12px",
                    boxShadow:'10px 1px 10px -4px #d1d5db',
                    position:'relative',
                    width:'100%',
                }}
                >
                  <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    height: '150px',
                    padding:'5px',
                   
                  }}
                  
                >
                  <Box
                  sx={{
                     backgroundPosition: 'center',
                    backgroundImage: `url(${image})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    marginBottom: "14px",
                     maxWidth:'90%',
                      borderRadius: "100px",
                      objectFit: "fill",
                      width:"200px",
                      // margin:'10px',
                      height:'120px',
                      position:'absolute',
                      top:5,
                      
                  }}
                  > 
                  </Box>
                  
                  {/* <img
                    src={image}
                    alt={name}
                    style={{
                      // maxWidth:'100%',
                      marginBottom: "14px",
                      borderRadius: "100px",
                      objectFit: "fill",
                      // width:"250px",
                      margin:'10px',
                      height:'100px',
                      // boxShadow:'15px 5px 15px -4px #d1d5db',
                      position:'absolute',
                      top:0,
                    }}
                     loading="lazy"
                  /> */}
                 </Box>
                 <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",    
                    marginTop:'-35px',    
                    // paddingBottom:'10px'           
                  }}
                 >
                  {language === "ar" ? <h4 className="Rubik">{name}</h4>  : <h4 className="Rounds">{name}</h4> }
                 </Box>
                 </Box>
                </Box>
              </Box>
     
    </Link>
  );
};
