import React, { useEffect, useRef, useState } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import CategoryButton from './CategoryButton';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { fetchCategories } from '../../../redux/Slices/CategoriesSlice';
import '@splidejs/react-splide/css';
import '@splidejs/react-splide/css/sea-green';
import '@splidejs/react-splide/css/core';
import { fetchMeals } from '../../../redux/Slices/MealsSlice';
const CategorySlider = () => {
  const { categories } = useAppSelector((state) => state.categories);
  const dispatch = useAppDispatch();
  const { language } = useAppSelector((state) => state.language);

  const longestTextLength = Math.max(
    ...categories.map((item) =>
      language === 'ar' ? item.ar_name.length : item.name.length
    )
  );

  const slideWidth = `${longestTextLength * 10}px`;

  const sliderRef = useRef<Splide | null>(null);

  useEffect(() => {
    if (categories.length === 0) {
      dispatch(fetchCategories());
      dispatch(fetchMeals({ id:1 }));
    }
  }, [dispatch, categories]);

  const selectedCatigoryString = localStorage.getItem('selectedCatigory');
  const selectedCatigory = selectedCatigoryString !== null ? parseInt(selectedCatigoryString) : 0;

  const mealsCategoryId = useAppSelector(state => state.meals.category_id);

  useEffect(() => {
    const sliderInstance = sliderRef.current?.splide;

    // Function to scroll to the selected category
    const scrollToSelectedCategory = () => {
      const selectedIndex = categories.findIndex((item) => item.id === selectedCatigory);
      if (selectedCatigory !== -1) {

        sliderInstance?.go(selectedIndex);
        dispatch(fetchMeals({ id:selectedIndex+1 }));
      }
    };
    // Start scrolling to the selected category after the component mounts
    scrollToSelectedCategory();
  }, [categories]);

  const getSlidesPerPage = () => {
    if (window.innerWidth >= 500) {
      return 4;
    } else if (window.innerWidth < 500 && window.innerWidth >= 350) {
      return 2;
    } else {
      return 1;
    }
  };

  const[slidesPerPage,seetslidesPerPage]=useState(getSlidesPerPage())
useEffect(()=>{
  seetslidesPerPage(getSlidesPerPage());
},[window.innerWidth])
  return (
    <Splide
      ref={sliderRef}
      options={{
        perPage: slidesPerPage,
        gap: 1,
        arrows: true,
        pagination: false,
        rewind: true,
        isVertical: false,
      }}
      aria-label="My Favorite Images"
      className="custom-scrollbar"
    >
    {categories.map((item, index) => {
      let name;
      if (language === 'ar') name = item.name;
      else name = item.ar_name;
      return (
        <SplideSlide key={index}>
          <CategoryButton id={item.id} name={name} width={slideWidth} />
        </SplideSlide>
      );
    })}
  </Splide>
  );
};

export default CategorySlider;
