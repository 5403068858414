import { Box, Stack, Typography , Dialog , DialogActions , DialogContent , useMediaQuery, Button , DialogContentText , DialogTitle, useTheme, FormControl, InputLabel, OutlinedInput} from "@mui/material";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { BaseURL } from "../../../API/BaseURL";
import { emptyCart } from "../../../redux/Slices/CartSlice";
import Loader from "../../../components/loader/loader";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CodeIcon from '@mui/icons-material/Code'; // or import LoyaltyIcon from '@mui/icons-material/Loyalty';

type Order_Item = { 
  meal_id: number;
  note: string;
  quantity: number;
};

type Order = {
  order_items: Order_Item[];
  table_id: string;
};

type OrderState = {
  isLoading: boolean;
  isError: boolean;
  order: Order; 
};

const initialStateOrder: OrderState = {
  isLoading: false,
  isError: false,
  order: {
    table_id: `${localStorage.getItem("table")}`,
    order_items: [],
  },
};

const Bill = () => {
  const cart = useAppSelector((state) => state.cart);
  const [orderInfo, setOrderInfo] = useState<OrderState>(initialStateOrder);
  const [open, setOpen] = React.useState(false);
  const [code , setCode] = React.useState('')
  const [codeOpen, setCodeOpen] = React.useState(false);
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useAppDispatch();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCodeClickOpen = () => {
    setCodeOpen(true);
  };

  const handleCodeClose = () => {
    setCodeOpen(false);
  };
  const navigate = useNavigate();
const [error,setError]=useState(false)
const [errorMessage,seterrorMessage]=useState('')
  const { language } = useAppSelector((state) => state.language);
console.log('cart',cart)
  const sendOrder = async (payment_method : 'cash' | 'code' | 'network') => {
    const orderItems = cart.order_items.map((item) => {
      return {
        meal_id: item.meal_id,
        note: item.note,
        quantity: item.quantity,
      };
    });
    const table = localStorage.getItem("table");
    console.log(table);
    setOrderInfo((prev) => {
      return {
        ...prev,
        isLoading: true,
        order: {
          table_id: `${localStorage.getItem("table")}`,
          order_items: orderItems,
        },
      };
    });
    type DataToSend = {
      table_id: string;
      order_items: any; // Replace 'any' with the actual type of your order items
      payment_method: 'code' | 'cash' | 'network';
      code?: string; // Optional property
  }
  
  let dataToSend: DataToSend = {
      table_id: `${localStorage.getItem("table")}`,
      order_items: orderItems,
      payment_method,
  };
  
  if (payment_method === 'code') {
      dataToSend.code = code;
  }
    axios
      .post(`${BaseURL}/orders`, dataToSend)
      .then((response) => {
        console.log(response)
        dispatch(emptyCart());
      navigate(`/?table_id=${localStorage.getItem("table")}`);
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          setError(true)
          console.error("Response data:", error.response.data);
          console.error("Response status:", error.response.status);
          console.error("Response headers:", error.response.headers);
          if (error.response.status === 422) {
            // Handle validation errors
            seterrorMessage('please refresh and try again')
            console.error("Validation errors:", error.response.data.errors);
            // You can update your UI or show an error message here based on the validation errors
          }
          if (error.response.status === 500) {
            // Handle validation errors
            seterrorMessage('somthing went wrong')
            console.error("Validation errors:", error.response.data.errors);
            // You can update your UI or show an error message here based on the validation errors
          }
        } else if (error.request) {
          // The request was made but no response was received
          console.error("No response received:", error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error:", error.message);
        }
        console.error("Error config:", error.config);
      });
  };

  return (
    <Box
      sx={{
        padding: "2rem",
        background: "#eee",
        position: "fixed",
        width: "100%",
      //  height: "33vh",
        bottom: "0",
        left: "0",
        borderRadius: "20px 20px 0 0",
        opacity: "0.9",
        filter: "drop-shadow(3px 0px 27px #999)",
      }}
    >
      {error==true?<Box>{errorMessage}</Box>:
     
      <>
      {orderInfo.isLoading ? (
        <Loader />
      ) : (
        <>
          <Stack
            direction={language === "ar" ? "row-reverse" : "row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            my={0}
            px={"1rem"}
          >
            <Typography fontSize={"0.8rem"}>
              {language === "ar" ? <h4 className="">: المجموع الكلي قبل الضريبة</h4> : <h4 className="Rubik">Total :</h4>}
            </Typography>
            <Typography fontSize={"0.8rem"}>
             
            
              <div className="" style={{display:"flex" }}>
            <div style={{marginRight:'4px'}} className=""> {language === "ar" ?  " ريال "  : ""} </div>

                <div className="Rounds">   {cart.total} {language === "ar" ? "" : "SAR"}</div>
                </div>  
            </Typography>
          </Stack>


          <Stack
            direction={language === "ar" ? "row-reverse" : "row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            my={0.1}
            px={"1rem"}
          >
            <Typography fontSize={"0.8rem"}>
              {language === "ar" ? <h4 className="">: ضريبة القيمة المضافة</h4> : <h4 className="Rubik">Tax :</h4>}
            </Typography>
            <Typography fontSize={"0.8rem"}>
             
            
              <div className="" style={{display:"flex" }}>
            <div style={{marginRight:'4px'}} className="Rubik">15% </div>

                </div>  
            </Typography>
          </Stack>



          <Stack
            direction={language === "ar" ? "row-reverse" : "row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            my={0}
            px={"1rem"}
          >
            <Typography fontSize={"0.8rem"}>
              {language === "ar" ? <h4 className="">: المجموع الكلي بعد الضريبة</h4> : <h4 className="Rubik">Total :</h4>}
            </Typography>
            <Typography fontSize={"0.8rem"}>
             
            
              <div className="" style={{display:"flex" }}>
            <div style={{marginRight:'4px'}} className="Rubik"> {language === "ar" ?  " ريال "  : ""} </div>

            <div className="Rounds">
  {(cart.total + (cart.total * 15 / 100)).toFixed(2)} {language === "ar" ? "" : "SAR"}
</div>                </div>  

            </Typography>
          </Stack>
          <div style={{display:'flex',justifyContent:'center'}}>
          <button
            onClick={() => {
              // sendOrder();
              handleClickOpen()
            }}
            style={{
              color: "white",
              background: "#313131",
              fontWeight: "bold",
              width: "70%",
              fontSize: "1rem",
              borderRadius: "10px",
              // padding: "1rem ",
              padding:'1px'
            }}
          >
            {language === "ar" ? <h3 className="Rubik">تأكيد الطلب </h3> : <h3 className="Rounds">Confirm Order</h3>}
          </button>
          </div>
          <Dialog
        open={open}
        maxWidth="lg"
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
        {language === "ar" ? "طرق الدفع" : "Payment Method"}
        </DialogTitle>
        <DialogContent>
          <Button onClick={() => {
            sendOrder('cash')
          }} color="primary" sx={{mb : 2}}     startIcon={<AttachMoneyIcon />} 
          variant="contained" fullWidth>{language === "ar" ? "كاش" : "Cash"}</Button>
          <Button onClick={() => {
            sendOrder('network')
            
          }} color="primary"  startIcon={<AccountBalanceIcon />}  sx={{mb : 2}} variant="contained" fullWidth>{language === "ar" ? "شبكة" : "Network"}</Button>
          <Button  color="primary"   startIcon={<CodeIcon />} // or <LoyaltyIcon />
 sx={{mb : 2}} variant="contained" onClick={handleCodeClickOpen} fullWidth>{language === "ar" ? "كود خصم" : "Diposit Code"}</Button>
        </DialogContent>
        <DialogActions>
          <Button autoFocus color="error" variant="contained" onClick={handleClose}>
             {language === "ar" ? "إلغاء" : "Close"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
      maxWidth="lg"
        open={codeOpen}
        onClose={handleCodeClose}
      >
        <DialogTitle>
        {language === "ar" ? "طرق الدفع" : "Payment Method"}
        </DialogTitle>
        <DialogContent>
          <FormControl>
            <InputLabel>Code</InputLabel>
            <OutlinedInput 
              type="text"
              name="code"
              label="code"
              onChange={(e) => {
                setCode(e.target.value)
              }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button autoFocus color="error" variant="contained" onClick={handleCodeClose}>
             {language === "ar" ? "الغاء" : "Close"}
          </Button>
          <Button onClick={() => {
            sendOrder('code')
          }} autoFocus color="success" variant="contained" >
             {language === "ar" ? "تأكيد" : "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>
        </>
       
      )}
      </>
        } 
    </Box>
  );
};

export default Bill;
