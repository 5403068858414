import React from "react";
import { Stack, Box, Typography } from "@mui/material";
import mealImage from "../../../assets/meal.jpg";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Link } from "react-router-dom";
import { Meal } from "../../../redux/Slices/MealsSlice";
import { ImageURL } from "../../../API/BaseURL";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { addToCart } from "../../../redux/Slices/CartSlice";
import AddIcon from '@mui/icons-material/Add';
import { abort } from "process";
import {motion} from 'framer-motion'
type MealItemProps = {
  meal: Meal;
};
 
const MealItem = ({ meal }: MealItemProps) => {
  const { language } = useAppSelector((state) => state.language);

  console.log(meal)

  const hover = {
    "&:hover":{
      // border:'1px solid #e5e7eb',
    // boxShadow:1,
    // borderRadius: "20px",
    
    }
  };

  return (
    <Stack
    sx={hover}
    >
    <Stack
      mt={4}
      sx={{
        // boxShadow:3,
        // border: "2px solid #fff",
        // background:'#fff',
        borderRadius: "10px",
        position:'relative',
      }}
      direction={language === "ar" ? "row-reverse" : "row"}
      gap={"1rem"}
      alignItems={"center"}
    >
      <Link to={`/meal/${meal.id}`}>
      <Box
        sx={{
          flexBasis: language !== "ar" ? "40%" : "0%",
        }}
      >
         {/* <Link
            to={`/meal/${meal.id}`}>
          <Typography 
          sx={{
            position:'absolute',
            left:-20,
            zIndex:10,
            top:60
          }}
          style={{border:2}}
          >
          <span> {language === "ar" ? <AddIcon style={{width:'25px',height:'25px' , background:'white' , borderRadius:'5px'}} />  : <AddIcon style={{width:'25px',height:'25px' , background:'white' , borderRadius:'5px'}} />} </span>
          </Typography>
          </Link> */}
        <Box
        sx={{
          boxShadow:2,
          filter: 'drop-shadow(8px 5px 5px #a1a1aa)',
        }}
        style={{borderRadius: "20%", height: "120px", width: "100px", position:'relative'}}>
          <motion.div
    animate={{
      scale: [1, 2, 2, 1, 1],
      rotate: [0, 0, 270, 270, 0],
      borderRadius: ["20%", "20%", "50%", "50%", "20%"],
    }}>
          <img
            style={{ borderRadius: "20%", height: "120px", width: "100px", }}
            loading="lazy"
            src={`${ImageURL}${meal.image}`}
          />
          </motion.div>
          {/* <Link
            to={`/meal/${meal.id}`}
            className="bump"
            style={{ color: "#8b2331",position:"absolute" ,bottom:-10,right:-10 ,backgroundColor:'#fff',borderRadius:'100%',width:'40px',height:'40px'}}
          
          >
            <AddCircleOutlineIcon style={{width:'40px',height:'40px'}} />
          </Link> */}
        </Box>
      </Box>
      </Link>
     <Link to={`/meal/${meal.id}`}>
      <Box
        sx={{
          flex: "1", 
          flexBasis: "100%",
        }}
      >
        <Stack
          justifyContent={"space-between"}
          direction={language === "ar" ? "row-reverse" : "row"}
        >
            <Typography color={"#333"} fontWeight={"600"} fontSize={"1rem"}>
              {language === "ar" ? <h2 className="Rubik" style={{fontSize:'15px'}}>{meal.ar_name}</h2>  : <h2 className="Rounds">{meal.name}</h2> }
            </Typography>
        </Stack>
        
        <Typography textAlign={language === "ar" ? "right" : "left"}>
          <span style={{ color: "#8b2331", fontWeight: "600" }}>
         {/**   {language === "ar" ? " المكونات :" : "Description "} */}
          </span>{" "}
          {language === "ar" &&  meal.description!='empty'? <div className="Rubik" style={{fontSize:'15px'}}  >{meal.ar_description}</div> :language !== "ar" &&  meal.description!='empty'? <div className="Rounds">{meal.description}</div>:''}
        </Typography>{" "}
        
        <Stack
        sx={{
          display:'flex',
          justifyContent:'space-between',
        }}
          direction={language === "ar" ? "row" : "row-reverse"}
          color={"#333"}
          fontWeight={"600"}
          fontSize={"0.9rem"}
          alignItems={'center'}
          style={{marginTop:'1rem' , display:'flex' }}
          width={'100%'}
        >
         
          <Typography   
          style={{border:2}}
          >
          <span > {language === "ar" ? <AddIcon style={{width:'30px',height:'30px' , background:'white' , borderRadius:'5px' ,position:'absolute' , left:0 , bottom:1}} />  : <AddIcon style={{width:'25px',height:'25px' , background:'white' , borderRadius:'5px' , position:'absolute' , right:0 , bottom:10}} />} </span>
          </Typography>

          <Stack
           textAlign={language === "ar" ? "right" : "left"}
          color={"#333"}
          fontWeight={"600"}
          fontSize={"0.9rem"}
          sx={{
            display:'flex',
            alignItems:'center',
            gap:2,
          }}
          direction={language === "ar" ? "row-reverse" : "row"}
          >
        <Typography
         
          // style={{marginTop:'1rem'}}
        >
          {" "}
          <span style={{ color: "#8b2331", fontWeight: "600" }}>
          {/**  {language === "ar" ? "السعر :" : "Price:"} */}
          </span>{" "}
<div className="flex" style={{display:'flex' , justifyContent:`${language=='ar'?'end':'start'}` ,fontSize:"10px"}}>

  <h2 style={{marginRight:'4px',fontSize:'11px'}} className="Rounds" >                  {language === "ar" ? " ريال "   : "  "}</h2>
  <h2 style={{fontSize:'11px'}}>              {meal.price} </h2>
  <h2 style={{marginLeft:'4px',fontSize:'11px'}} className="Rounds">          {language === "ar" ? " "  : " SAR "}</h2>

</div>
        </Typography>

        <Typography>
             <span style={{ color: "#8b2331", fontWeight: "300" }}>
             <div className="flex" style={{display:'flex' , justifyContent:`${language=='ar'?'end':'start'}` ,fontSize:"10px"}}>

  <h2 style={{marginRight:'4px',fontSize:'11px'}} className="Rounds ">                     {language === "ar" ? " سعرة حرارية "   : "  "}</h2>
  <h2 style={{fontSize:'11px'}}>              {meal.calories} </h2>
  <h2 style={{marginLeft:'4px',fontSize:'11px'}} className="Rounds">          {language === "ar" ? " "  : " CAL "}</h2>

</div>
             </span>

          </Typography>

        </Stack>
        </Stack>
      </Box>
      </Link>
    </Stack>
    </Stack>
  );
};

export default MealItem;
