import React from "react";
import { Stack, Box, Typography } from "@mui/material";
import mealImage from "../../../assets/meal.jpg";
import Counter from "../../../components/Counter";
import { MealItem } from "../../../redux/Slices/CartSlice";
import { ImageURL } from "../../../API/BaseURL";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../redux/store";

type CartItemsProps = {
  item: MealItem;
};

const CartItem = ({ item }: CartItemsProps) => {
  const { language } = useAppSelector((state) => state.language);
console.log('total_price',item.total_price)
console.log('total_price',item)

  return (
    <Stack
      mt={4}
      sx={{
        background: "#fff",
        padding: "0 0 0 0.2rem",

        borderRadius: "10px",
      }}
      direction={language === "ar" ? "row-reverse" : "row"}
      gap={"1rem"}
      alignItems={"center"}
    >
   <Box
  sx={{
    maxWidth:'200px',
    flexBasis: "100%",
    borderRadius: "10px",
    height: "200px",
    backgroundSize: "cover",
    backgroundImage: `url(${ImageURL}${item.image})`
  }}
/>
      <Box
        sx={{
          flex: "1",
          flexBasis: "60%",
          padding: "0.8rem 0.5rem",
        }}
      >
        <Stack
          justifyContent={"space-between"}
          direction={language === "ar" ? "row-reverse" : "row"}
        >
          <Link to={`/meal/${item.meal_id}`}>
            <Typography
              textAlign={language === "ar" ? "right" : "left"}
              color={"#333"}
              fontWeight={"600"}
              fontSize={"1rem"}
            >
              {language === "ar" ? <h3>{item.ar_name}</h3> : <h3 className="Rounds">{item.meal_name}</h3>}
            </Typography>
          </Link>
        </Stack>

        <Typography
          textAlign={language === "ar" ? "right" : "left"}
          style={{ color: "GrayText" }}
        >
          {/**    <span style={{ color: "#8b2331", fontWeight: '600' }}>{language === 'ar' ? " الملاحظة: " : 'Note:'}</span>  */}{" "}
          <h4 style={{marginTop:'-20px'}} className="Rounds">{item.note}</h4>
        </Typography>
        <Stack
          gap={1}
          direction={"column"}
          justifyContent={"start"}
          alignItems={language === "ar" ? "end" : "start"}
        >
          <Typography
            sx={{ flex: "1" }}
            color={"#333"}
            fontWeight={"600"}
            fontSize={"0.9rem"}
          >
          
            <div className="" style={{display:"flex" }}>
            <div  style={{marginRight:'4px'}}> {language === "ar" ?  " ريال "  : ""} </div>

                <div className="Rounds">  {item.price_per_one *item.quantity} {language === "ar" ? "" : "SAR"}</div>
                </div>  
          </Typography>
          <Counter id={item.meal_id} color="#ddd" />
        </Stack>
      </Box>
    </Stack>
  );
};

export default CartItem;
