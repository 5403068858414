export const varints = {
  routeVariants: {
    initial: {
      y: "100vh",
      opacity: 0,
    },

    final: {
      y: "0vh",
      opacity: 1,
      transition: {
        type: "spring",
        mass: 0.4,
      },
    },
  },
  childrenvarinats: {
    initial: {
      y: "50px",
      opacity: 0,
    },

    final: {
      y: "0",
      opacity: 1,
      transition: {
        duration: 0.5,
        delay: 0.5,
      },
    },
  },
  childrenvarinatsonx: {
    initial: {
      x: "50px",
      opacity: 0,
    },

    final: {
      x: "0",
      opacity: 1,
      transition: {
        duration: 0.5,
        delay: 1,
      },
    },
  },
};
