import React from 'react'
import { Box, Grid, Stack, Typography } from "@mui/material";
import drinks from "../../../assets/drinks.png"
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { CategoryItem } from './CategoryItem';
import { useAppSelector } from '../../../redux/store';
import Loader from '../../../components/loader/loader';
import { Category } from '../../../redux/Slices/CategoriesSlice';
import { ImageURL } from '../../../API/BaseURL';
import { Grade } from '@mui/icons-material';

const Categories = () => {

    const categories = useAppSelector(state => state.categories)
    const { language } = useAppSelector((state) => state.language)


    const loading = categories.isLoading
    const error = categories.isError
    const categoryArray = (categories.categories)

    return (
        <> {loading ? <Loader /> :  
        <Grid  container rowSpacing={2} columnSpacing={3} sx={{ mb: "74px" }}
        my={3}>
            {categoryArray.map((cat: Category, index) => {
                let catName;
                if (language === 'ar')
                    catName = cat.name
                else catName = cat.ar_name
                console.log(cat)
                return <Grid item xs={6} md={4}> <CategoryItem key={index} image={`${ImageURL}${cat.image}`} id={cat.id} name={catName} /> </Grid>
            })}
        </Grid>
        }
        </>

    )
}

export default Categories