import React, { useState } from 'react'
import { Box, Typography } from "@mui/material";
import { useAppSelector } from '../../../redux/store';
import image from '../../../assets/building.png'
import { Typewriter } from 'react-simple-typewriter';
const Landing = () => {
    const { language } = useAppSelector((state) => state.language)
    return (
        <>
            <Box sx={{
                backgroundImage: `url(${image})`,
                backgroundPosition: 'center',
                height: '50vh',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                position:'relative',
                padding: '2rem',
                borderRadius: '5px',
                marginBottom:'25px'

            }}>
                <Box sx={{
                    color: 'white',
                    position: 'absolute',
                    bottom: '2rem',
                    right: language === 'ar' ? '2.5rem' : 'calc(100% - 11rem)',


                }}>
                    <Typography fontSize={'1.5rem'}>{language === 'ar' ?<h4 className="Rubik"> اهلا بكم في </h4>: <h4 className='Rounds'>Welcome To</h4>}</Typography>
                    <Typography fontWeight={'bold'} fontSize={'2rem'}>{language === 'ar' ?<h4 className="Rubik"  style={{marginTop:'-30px'}}> لو صالون </h4>: <h3 className='Rounds' style={{marginTop:'-30px'}}>Le Salon</h3>}</Typography>
                    {/* <Typewriter
    cursor
    cursorBlinking
    delaySpeed={1000}
    deleteSpeed={25}
    loop={0}
    typeSpeed={75}
    words={[
      ' اهلا بكم في لو صالون',
    ]}
  /> */}
                </Box>
            </Box>
            {/* <Box textAlign={language === 'ar' ? 'right' : 'left'} color={'#333'} my={'1rem'}>
                <Typography fontSize={'1.5rem'}>{language === 'ar' ? "ماذا ترغب" : 'What would you like'}</Typography>
                <Typography mt={'0'} fontWeight={'bold'} fontSize={'2rem'}>{language === 'ar' ? "ان تطلب" : 'to order ?'}</Typography>
            </Box> */}
        </>
    )
}

export default Landing