import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import { fetchMeals } from "../../../redux/Slices/MealsSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { useSelector } from "react-redux";

type categoryButtonProps = {
  name: string;
  id: number;
  width: string;
};

const CategoryButton = ({ name, id, width }: categoryButtonProps) => {
  const dispatch = useAppDispatch();
  const handleSelectCategory = () => {
    dispatch(fetchMeals({ id }));
  };

  const mealsCategoryId = useAppSelector((state) => state.meals.category_id);
  const { language } = useAppSelector((state) => state.language);

  return (
    <button
      style={{
        width: width,
        margin: "0 1rem",
        background: mealsCategoryId === id ? "#333" : "#fff",
        borderRadius: "100px",
        display: "flex",
        justifyContent: "center",
      }}
      onClick={handleSelectCategory}
    >
      <Typography
        sx={{
          fontSize: "1.2",
          // padding: '0.4rem 0.8rem',
          width: "fit-content",
          color: mealsCategoryId === id ? "#fff" : "#333",
          fontWeight: "600",
          whiteSpace: "nowrap",
          height: "35PX",
        }}
      >
        {language === "ar" ? (
          <h5 className="Rubik" style={{ marginTop: "6px" }}>
            {name}
          </h5>
        ) : (
          <h4 className="Rounds" style={{ marginTop: "6px" }}>
            {name}
          </h4>
        )}
      </Typography>
    </button>
  );
};

export default CategoryButton;
