import { Box, Stack } from '@mui/material'
import React from 'react'
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import Logo from '../../assets/af081fd7-d49b-413c-ba1a-ecac9c39e93a.png'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Language from './Language';
import { useSelector } from 'react-redux';
const Navbar = () => {
    const { order_items } = useAppSelector(state => state.cart)
    const location = useLocation()
    const navigate = useNavigate()


    return (
        <Stack color={'#323232'} sx={{ backgroundColor:'#fff' ,p:1, marginBottom:2}} fontSize={'1rem'} p={3} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            {/**  {location.pathname === '/' ?
              <Box sx={{}}>< img style={{ width:'50px' }} src={Logo} /> </Box> :
                <button onClick={() => { if (location.pathname === '/cart') navigate('/meals'); else if (location.pathname === '/meals') navigate('/'); else navigate(-1) }} ><ChevronLeftIcon style={{ fontSize: '2rem' }} /></button>
            } */}
            <Stack direction={'row'} width={'100%'} justifyContent={'space-between'} alignItems={'center'} gap={3} >
                <Box>
            <Language />
            </Box>

            
                <Link style={{ color: '#323232', position: 'relative' }} to={'/cart'}><ShoppingBagOutlinedIcon style={{
                    fontSize: '1.8rem'
                }} />

                    <Box sx={{
              background: '#fff',
                        textAlign: 'center',
                        color: '#8b2331',
                     //   border:'2px solid #8b2331',
                        fontWeight: 'bold',
                        width: '18px',
                        height: '18px',
                        borderRadius: '50%',
                        position: 'absolute',
                        top: '-3px',
                        right: '-9px',
                        fontSize: '0.8rem'

                    }}>
                        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}>
                            {order_items.length}
                        </Box>
                    </Box>
                </Link>
            </Stack>

        </Stack >
    )
}

export default Navbar