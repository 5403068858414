import React from 'react';
import './App.css';
import Login from './Pages/Login';
import Navbar from './components/Navbar/Navbar';
import Home from './Pages/Home/Home';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Meals from './Pages/Meals/Meals';
import MealDetails from './Pages/MealDetails/MealDetails';
import Cart from './Pages/Cart/Cart';
import { BaseURL } from './API/BaseURL';
import { IconButton, Stack } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useAppSelector } from './redux/store';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';


function App() {
  const location = useLocation();
  const navigate = useNavigate();
   const { language } = useAppSelector((state) => state.language);

  return (
    <>
          <Navbar />

    <div className="App container">
      <Routes location={location} key={location.pathname}>
        <Route path='/' element={<Home />} />
        <Route path='/meals' element={<Meals />} />
        <Route path='/meal/:id' element={<MealDetails />} />
        <Route path='/cart' element={<Cart />} />
      </Routes>

      
      {
        location.pathname !== '/' &&  location.pathname !=='/meals' && (
          
          <div>
            <Stack direction={language === "ar" ? "row-reverse" : "row"}>
            {language === "ar" ? <div className="">
              <IconButton
            sx={{
              position : 'fixed',
              width : '45px',
             
              right:'10px',
              bottom : '45px',
              height : '45px',
              borderRadius : '50%',
              backgroundColor : '#b91c1c',
              color : 'white',
              fontWeight : 'bold',
              "&:hover" : {
                backgroundColor : '#b91c1c',
              },
              transform: 'rotate(180deg)', // Rotate by 180 degrees

            }}
            onClick={() => {
              navigate(-1)
            }}
          >
            <ArrowBack/>
          </IconButton>
            </div>  : <div className="">
              <IconButton
            sx={{
              position : 'fixed',
              width : '45px',
              left : '10px',
              bottom : '45px',
              height : '45px',
              borderRadius : '50%',
              backgroundColor : '#b91c1c',
              color : 'white',
              fontWeight : 'bold',
              "&:hover" : {
                backgroundColor : '#b91c1c',
              }
            }}
            onClick={() => {
              navigate(-1)
            }}
          >
          <ArrowBack />
          </IconButton>
            </div> }
          
        
           </Stack>
         
           </div>
        )
        
      }
<h2 className="Rounds" style={{
  textAlign: 'center',
  fontWeight: '1000', // Semi-bold, adjust the value as needed
  fontSize:"10px"
}}>
  Powered by <span style={{
  textAlign: 'center',
  fontWeight: '1000', // Semi-bold, adjust the value as needed
  fontSize:"15px"
}}>Goma</span>
</h2>    </div>
    </>
  );
}

export default App;
