import { Box, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import { motion } from 'framer-motion'
import { useParams } from "react-router-dom";
import { BaseURL, ImageURL } from "../../API/BaseURL";
import { Meal } from "../../redux/Slices/MealsSlice";
import { addToCart } from "../../redux/Slices/CartSlice";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../redux/store";
import './App.css'




type MealInfoState = {
    isLoading: boolean,
    isError: boolean,
    meal: Meal
}

const mealStateInitialState: MealInfoState = {
    isLoading: false,
    isError: false,
    meal: {
        id: 0,
        name: '',
        calories:0 ,
        description: '',
        image: '', 
        price: 0,
        ar_name: '',
        ar_description: '',
        visible:0,
        position:0,
    }

}

const MealDetails = () => {
    const { id } = useParams();
    const [mealInfo, setMealInfo] = useState<MealInfoState>(mealStateInitialState)
 const [count,setCount]=useState(1)
    const dispatch = useDispatch()
    const noteRef = useRef<HTMLInputElement | null>(null)
    const addToCartHandler = () => {
        let note;
        if (noteRef?.current) {
            note = noteRef.current.value
            console.log(noteRef.current.value)
        }
        console.log(

            {
                meal_id: Number(mealInfo.meal?.id),
                image: mealInfo?.meal?.image,
                quantity: count,
                note: inputValue ? inputValue : '',
                meal_name: mealInfo.meal.name,
                price_per_one: mealInfo.meal.price,
                total_price: count*mealInfo.meal.price,
                ar_name: mealInfo.meal.ar_name,
            }
        )
        dispatch(addToCart({
            meal_id: Number(mealInfo.meal?.id),
            image: mealInfo?.meal?.image,
            quantity: count,
            note: inputValue ? inputValue : '',
            meal_name: mealInfo.meal.name,
            total_price: count*mealInfo.meal.price,
            price_per_one: mealInfo.meal.price,
            ar_name: mealInfo.meal.ar_name,
        }))

    }
    const { language } = useAppSelector(state => state.language)
    const getMeal = async () => {
        setMealInfo(prev => {
            return { ...prev, isLoading: true }
        })

        try {
            const response = await fetch(`${BaseURL}/meals/${id}`, {
                method: 'GET',
            })
            const data = await response.json();
            setMealInfo(prev => {
                return { ...prev, isLoading: false, meal: data.data }
            })

        } catch (error) {
            setMealInfo(prev => {
                return { ...prev, isLoading: false, isError: true }
            })
        }

    }
    useEffect(() => {
        getMeal()
    }, [])

   
    const [inputValue, setInputValue] = useState('');

    // Check if the input value has Arabic letters using a regular expression
    const isArabicContent = /[\u0600-\u06FF]/.test(inputValue);

    // Set the direction based on the language or content
    const direction = isArabicContent ? 'rtl' : 'ltr';
    

   
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
                            <Typography
                            sx={{display:'flex',
                            justifyContent:'center',
                        }}
                             fontWeight={'600'} fontSize={'1.3rem'} >{language === 'ar' ?  <h3 className="Rubik">{mealInfo?.meal?.ar_name}</h3> : <h3 className="Rounds">{mealInfo?.meal?.name}</h3>}
                            
                            </Typography>
<Typography
sx={{
    display:'flex',
     flexDirection: 'column',
     justifyContent:'space-between'
}}
>
            {/* BAckgorund IMAGE */}
            <div>
            <Box
            sx={{
                position:'relative',
                 display:'flex',
                 flexDirection: 'column',
                 alignItems:'center',
                 justifyContent:'center',
            }}
            >
            <Box
                sx={{
                    backgroundRepeat: "no-repeat",
                    width: "250px",
                    height: "250px",
                    // position: "absolute",
                   
                    borderRadius:'10px',
                    backgroundImage: `url(${ImageURL}${mealInfo.meal?.image})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    // zIndex: '-1'
                }}
            >
            </Box>
            
            {/* <Box
            sx={{
                position:'absolute',
                width:'100%',
                height:'100%',
                bgcolor:'black',
                right:0,
                left:0,
                top:58,
                opacity:'0.4',
            }}
            ></Box> */}
            <Box
            sx={{
                 display:'flex',
     flexDirection: 'column',
     justifyContent:'space-between',
     gap:'5px',
     marginTop:'-20px'
            }}
            >
            <Box 
            sx={{
                width:'40px',
                height:'40px',
                bgcolor:'white',
                borderRadius:'100PX',
                boxShadow:2,   display:'flex' , justifyContent:'center' ,  fontSize:'30px' ,alignItems:'center'
            }}
            >
              <button style={{
                display:'flex' , justifyContent:'center' ,  fontSize:'30px' ,alignItems:'center'
              }}
              onClick={() => setCount(count + 1)}
              ><p style={{color:"black"}}>+</p></button>
            </Box>

            <Box 
  sx={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100px',
    fontSize: '25px',
    textAlign: 'center',
  }}
>
  
    {count}
  
</Box>

            <Box 
            sx={{
                width:'40px',
                height:'40px',
                bgcolor:'white',
                borderRadius:'100PX',
                boxShadow:2,
                display:'flex' , justifyContent:'center' ,  fontSize:'30px' ,alignItems:'center'

            }}
            >
              <button style={{
                display:'flex' , justifyContent:'center' ,  fontSize:'30px' ,alignItems:'center'
              }}
              onClick={() => {
        count > 1 && setCount(count - 1);
      }}
              ><p style={{color:"black"}}>-</p></button>
            </Box>
            </Box>
            </Box>
            </div>
            <Box 
            sx={{
                display:'flex',
                justifyContent:'center',
                // backgroundImage:'linear-gradient(to bottom right, #fc0345,#fc03b1,#4a03fc)'
            }}
            >
            <Box
                sx={{
                    // position: 'absolute',
                    // top:{xs:300, sm:500},
                    bottom:0,
                    padding: '2rem',
                    marginTop:'-1rem',
                    textAlign: 'center',
                    height:'100%'
                    // background: 'rgba(255, 255, 255, 0.3)',
                    // borderRadius: '20px',
                    // opacity: '0.9',
                    // filter: 'drop-shadow(8px -35px 32px black)',

                }}>

                {/* <Typography color={"white"} fontWeight={'600'} fontSize={'1.3rem'} >{language === 'ar' ?  <h3 className="Rubik">{mealInfo?.meal?.ar_name}</h3> : <h3 className="Rounds">{mealInfo?.meal?.name}</h3>}</Typography> */}
                <Typography  m={"0 auto"} sx={{
                    width: { xs: '98%', sm: '80%', md: '70%', lg: '60%' },
                    fontSize:'20px'
                }}>
                        {language === "ar" &&  mealInfo.meal.description!='empty'? <div className="Rubik">{mealInfo.meal.ar_description}</div> :language !== "ar" &&  mealInfo.meal.description!='empty'? <div className="Rounds">{mealInfo.meal.description}</div>:''}
  
                </Typography>
                <Box sx={{ textAlign: 'center', margin: '1rem auto', width: '100%' ,color:'#b91c1c'}}>
                <Typography  fontWeight={'600'} fontSize={'1.3rem'}>{language === 'ar' ? <h3 className="Rubik" style={{whiteSpace:"nowrap"}}>ملاحظة الى المطبخ</h3> : <h3 className="Rounds">Note To Kitchen</h3>} </Typography>
                <TextField
            fullWidth
            rows={2}
            multiline
            value={inputValue}
            onChange={ (e)=>{setInputValue(e.target.value)}}
            inputProps={{ dir: direction }}
            color="error"
            sx={{
                border: 1,
                borderColor: 'white',
                borderRadius: '5px',
            }}
        />

                </Box>
                <div style={{display:"flex" ,justifyContent:'center'}}>
                

                <Stack sx={{
                    width: { xs: '98%', sm: '80%', md: '70%', lg: '60%' },
                    // margin: '2rem auto 1rem'

                }} justifyContent={'center'} direction={'row'} alignItems={'center'}>
                    <Link to={'/meals'}>
                        
                    <Box  onClick={addToCartHandler} style={{
                        background: '#b91c1c',
                        padding: '0.1px 40px',
                        color: '#eee',
                        borderRadius: '10px',
                        width:'100%',
                        fontWeight: "bold",
                         whiteSpace:'nowrap',
                    }}>{language === 'ar' ?<h4  className="Rubik"> إضافة الى السلة</h4> : <h4  className="Rounds">Add To Cart</h4> }
                    </Box>
                    </Link>
                </Stack>

          {/**     <Stack sx={{
                    width: { xs: '98%', sm: '80%', md: '70%', lg: '60%' },
                    margin: '2rem auto 1rem'

                }} justifyContent={'center'} direction={'row'}>

                   <div style={{display:'flex', gap:'10px' , background:'#e5e7eb', height:'30px' , borderRadius:'5px'}}>
                    <div style={{background:'white' , height:'30px' , width:'30px' , borderRadius:'5px' , alignItems:'center' , display:'flex' , justifyContent:'center'}}>+</div>
                    <div style={{ alignItems:'center' , display:'flex' , justifyContent:'center'}}>2</div>
                    <div style={{background:'white' , height:'30px' , width:'30px' , borderRadius:'5px', alignItems:'center' , display:'flex' , justifyContent:'center'}}>-</div>
                   </div>
                </Stack>*/}   
              {/* <Stack
  sx={{
    width: { xs: '98%', sm: '80%', md: '70%', lg: '60%' },
    margin: '0 auto',
    marginTop:"18px",
    justifyContent: 'center',
    alignItems: 'center',
  }}
  
>
  <div
    style={{
      backgroundColor: '#fff',
      borderRadius: '10px',
      padding: '0.5rem 1.5rem',
      display: 'flex',
      alignItems: 'center',
    }}
  >
    <button
      style={{
        border: 'none',
        background: 'none',
        cursor: 'pointer',
      }}
      onClick={() => {
        count > 1 && setCount(count - 1);
      }}
    >
      <span style={{ fontSize: '1rem' }}>-</span>
    </button>
    <Typography variant="body1" fontWeight="bold" sx={{ marginX: '1rem' }}>
      {count}
    </Typography>
    <button
      style={{
        border: 'none',
        background: 'none',
        cursor: 'pointer',
      }}
      onClick={() => setCount(count + 1)}
    >
      <span style={{ fontSize: '1rem' }}>+</span>
    </button>
  </div> 
</Stack> */}


                </div>
               
            </Box>
            </Box>
            </Typography>
        </motion.div>
    );
};

export default MealDetails;
